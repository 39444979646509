<template>
  <div>
    <rxNavBar title="请假审核"></rxNavBar>
    <div class="headerBlock">
      <div  :class="item.approvalStatus=='0'?'top-title-orange':item.approvalStatus=='1'?'top-title-green':'top-title-red'">
        {{ item.approvalStatusStr }}</div>
      <div class="leaveText">
        <div class="leaveMessage orangeColor">{{ item.vacationType }}</div>
        <div class="leaveMessage orangeColor">{{ item.vacationBeginTime }}&nbsp;至&nbsp;{{ item.vacationEndTime }}</div>
        <div class="leaveMessage">申请人：<span class="normalFont">{{ item.approvalStaffName }}</span></div>
        <div class="leaveMessage">时长：<span class="normalFont">{{ item.vacationTimeLong }}天</span></div>
        <div class="leaveMessage">工作交接人：<span class="normalFont">{{ handoverStaff }}</span></div>
        <div class="leaveMessage">事由：<span class="normalFont">{{ item.remarks }}</span></div>
      </div>
    </div>
    <div class="imgSwipe" v-if="picList.length>0" >
      <van-swipe :loop="false" :width="115" :show-indicators="false">
        <van-swipe-item   v-for=" (item,i) in picList" :key="item.id">
          <div class="applyDetailImg">
            <img style="object-fit: contain" :src="item" @click="onChangeApply(i)"/>
          </div>
        </van-swipe-item>
        <div class="custom-indicator">
          {{picList.length}} 图
        </div>
      </van-swipe>
    </div>
    <van-image-preview v-model="showApply" :images="picList"  :start-position="start"></van-image-preview>
    <div class="checkInfos">审核信息</div>

    <!--已通过-->
    <div class="bottom-check" v-for="(item,index) in approvalHistoryInfoList" :key="index">
      <div class="bottom-text">
        <span class="bottom-text-left">审核人：</span>
        <span class="bottom-text-right">{{ item.approvalStaffName }}</span>
      </div>
      <div class="bottom-text">
        <span class="bottom-text-left">审核时间：</span>
        <span class="bottom-text-right">{{ item.addTime }}</span>
      </div>
      <div class="bottom-text" v-if="item.approvalResult=='0'">
        <span class="bottom-text-left ">审核结果：</span>
        <span class="bottom-text-right" style="color: #4DC7AF">{{ item.approvalResultStr }}</span>
      </div>
      <div class="bottom-text" v-if="item.approvalResult=='1'">
        <span class="bottom-text-left">审核结果：</span>
        <span class="bottom-text-right" style="color: #FF7357">{{ item.approvalResultStr }}</span>
      </div>
      <div class="bottom-text" v-if="item.approvalResult=='2'">
        <span class="bottom-text-left">审核结果：</span>
        <span class="bottom-text-right">{{ item.approvalResultStr }}</span>
      </div>
      <div class="bottom-text" v-if="item.approvalResult=='1'">
        <span class="bottom-text-left">驳回原因：</span>
        <span class="bottom-text-right">{{ item.dictionaryTitle }}</span>
      </div>
      <div class="bottom-text">
        <span class="bottom-text-left">审核意见：</span>
        <span class="bottom-text-right">{{ item.remarks }}</span>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    NavBar, Button, ImagePreview, Image as VanImage, popup, picker, Swipe, SwipeItem
  } from 'vant';
import BScroll from "better-scroll";
import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {responseUtil} from "../../../libs/rongxunUtil";
import {vacationAgree, vacationDetailInit, vacationRefuse} from "../../../getData/getData";
  import rxNavBar from "../../../components/rongxun/rx-navBar/navBar"

export default {
  name: "LeaveReviewDetails",
  components: {
    rxNavBar,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [popup.name]: popup,
    [picker.name]: picker,
    [Swipe .name]:Swipe,
    [SwipeItem .name]:SwipeItem,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [BScroll.name]: BScroll
  },
  data() {
    return {
      start : '',
      showApply : false,
      picList : [],
      //申请历史列表
      approvalHistoryInfoList:[],
      //审批id
      id: '',
      //审核返回的item信息
      item: {},
      //交接人
      handoverStaff: '',
      //请假员工ID
      approvalApplicantStaff_id: '',
    }
  },
  //生命周期初始化
  created() {
    this.id = this.$route.query.id
    this.handoverStaff = this.$route.query.handoverStaff
    this.vacationDetailInit()
  },
  methods: {
    onChangeApply(i){
      this.start=i
      this.showApply=true
    },
    //初始化接口
    vacationDetailInit() {
      let that = this
      let initData = {}
      initData.id = that.id
      vacationDetailInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.item = response.data.data.item
          that.item.vacationTimeLong = Math.ceil(that.item.vacationTimeLong / 24)
          that.refuseReasonList = response.data.data.refuseReasonList
          that.approvalHistoryInfoList = response.data.data.approvalHistoryInfoList
          if(response.data.data.addtion.length>0){
            for(let i=0;i<response.data.data.addtion.length;i++){
              that.picList[i] = response.data.data.addtion[i].path
            }
          }
        })
      })
    },

  }
}
</script>

<style scoped lang="less">
.reject {
  padding-left: 30px;
  padding-top: 6px;
  width: 315px;
  height: 24px;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.headerBlock {
  background-color: white;
  border-radius: 6px;
  width: 9.2rem;
  height: available;
  margin: 16.2px auto 0px
}

/*.wait-approve {*/
/*  width: 43px;*/
/*  height: 16px;*/
/*  line-height: 17px;*/
/*  font-size: 10px;*/
/*  text-align: center;*/
/*  color: white;*/
/*  border-radius: 8px 0;*/
/*  background: linear-gradient(to right, #ffb96f, #ff6d44);*/
/*}*/
.top-title-orange,.top-title-green,.top-title-red {
  width: 38px;
  height: 15px;
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  border-radius: 8px 0 ;
  color: white;
}
.top-title-orange{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-green {
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-red {
  background: linear-gradient(to right, #fc401b, #e02321);
}
.leaveText {
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 25px;
  padding-bottom: 15px;
}

.leaveMessage {
  font-size: 14px;
  font-weight: bold;
}

.normalFont {
  font-size: 13px;
  font-weight: normal;
  color: #7d7b7b;
}

.orangeColor {
  color: rgba(255, 93, 59, 1);
}

.feedback {
  padding-top: 30px;
  padding-left: 15px;
  top: 318px;
  width: 48px;
  height: 17px;
  color: rgba(255, 93, 59, 1);
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  font-family: PingFangSC-Semibold;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}

.part-inputpart-dropDownList {
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -17px;

}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 15px;
  width: 30px;
  padding-right: 10px;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  font-size: 15px;
  width: 220px;
  padding-left: 10px;
}

.part-inputpart-row-right {

  float: right;
  padding-right: 24px;
}

.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 8px;
  width: 345px;
  margin: 15px 15px 0px 15px;
}

.content-divide {
  width: 1px;
  height: 14px;
  color: #efefef;
}

.content-divides1 {

  font-size: 14px;

  color: #EFEFEF;

}

.content-divides2 {
  font-size: 14px;

  color: #EFEFEF;
}

.suggestTitle {
  margin-left: 15px;
  margin-top: 15px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.textArea {
  margin: 45px 10px 20px 15px;
  resize: none;
  padding: 15px;
  height: 90px;
  width: 285px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(250, 250, 250, 1);
}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  font-size: 14px;
  float: right;
  padding-right: 31px;
}

.inputPrice {
  color: rgba(255, 93, 59, 1);
  width: 100%;
  height: 28px;
  padding-left: 10px;
  line-height: 28px;
  font-size: 15px;
  //font-weight: bold;
  border: none;
}

.passText {
  flex: 1;
  padding-right: 22px;
  color: #999999;

}

.rejectText {
  padding-left: 30px;
  padding-right: 40px;

}

.rightText {
  margin-left: auto;
  font-size: 14px;
  color: #999999;
}

.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 10px;
  font-size: 28px;
}

.orangeColor {
  color: rgba(255, 93, 59, 1);
}

.choosedStyle {
  color: rgba(255, 93, 59, 1);
  font-weight: bold;
}

.blockTitle {
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}

.nav-bar {
  background-color: #f8f8f8;
  border: none;
  font-weight: bold;
}

.saveButton_Disable {
  background-color: rgba(184, 184, 184, 0.2);
  width: 9rem;
  height: 1.22rem;
  border-radius: 8px;
  margin: 30px 19px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}

.displayBlock {
  //position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  width: 340px;
  height: 185px;
  margin: 15px 15px 0px 15px;
}

.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  line-height: 1rem;
  height: 50px;
  margin: 15px 15px 0px 15px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}

.part-inputpart-dropDownList {
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -17px;

}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: rgba(255, 93, 59, 1);
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

}
.checkInfos{
  margin: 30px 0 6px 15px;
  height: 17px;
  line-height: 17px;
  color: rgba(255, 93, 59, 1);
  font-size: 12px;
  text-align: left;
  font-weight: 900;
  font-family: PingFangSC-Semibold
}

.text-red{
  color: #FF7357;
}
.text-green{
  color: #4DC7AF;
}

/*下拉*/
.downMenu {
  display: flex;
  align-items: center;
  width: 70%;

  .arrows {
    height: 0;
    width: 0;
    border: 3.5px solid;
    border-color: black transparent transparent transparent;
  }

  .downMenuValue {
    margin-left: 10px;
    width: 95%;
    font-size: 14px;
  }
}

.normalBlock {
  width: 92%;
  height: 50px;
  /*border: 1px solid red;*/
}

.displayBlock {
  width: 92%;
}

.bottom-check{
  width: 345px;
  margin: 0 15px 15px 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  font-size: 15px;
  text-align: left;
  overflow: hidden;
}
.bottom-text{
  height: auto;
  line-height: 24px;
  margin-left: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bottom-text-left{
  font-weight: bold;
}
.bottom-text-right{
  font-size: 14px;
  color: #999999;
}
/*    申请详情图片DIV*/
.imgSwipe {
  /*position: relative;*/
  height: 108px;
  width: 92%;
  margin: 15px auto;
  background-color: #FFFFFF;
  border-radius: 8px;
  /*图片*/

  .applyDetailImg {
    width: 100px;
    height: 75px;
    border-radius: 8px;
    overflow: hidden;
    margin: 15px 0px 18px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e1eaec;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  /*    轮播的显示图片数*/
  .custom-indicator{
    position: absolute;
    left: 15px;
    top: 20px;
    padding: 1px 7px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: white;
  }
}

</style>